<template>
  <div class="drop-buy-container">
    <BaseText
      class="w-full md:text-center"
      tag="h1"
      testId="title-drop-player"
      size="headline-large"
      loadingHeight="36px"
      loadingWidth="95%"
    >
      {{ playerName }}
    </BaseText>

    <BaseText
      tag="h3"
      testId="title-drop-player"
      loadingHeight="22px"
      size="subheadline-medium"
      style="font-weight: 400"
    >
      {{ dropName }}
    </BaseText>

    <div class="subheadline-small mt-s24">
      {{ $t('drop.how_much_spend') }}
    </div>

    <CustomInput
      class="mt-s12"
      :masked="false"
      placeholder="$0"
      testId="usd"
      filter="money"
      v-model="usdAmount"
      @input="updateAmount"
    >
      <div slot="icon">
        <div
          @click="updateAmount('MAX')"
          class="text-n-sm font-bold text-text-active-2 cursor-pointer"
          v-text="$t('drop.max')"
        />
      </div>
    </CustomInput>

    <div class="flex flex-col -mt-s12">
      <div class="flex flex-row justify-between">
        <label v-text="$t('drops.balance')" />
        <div class="font-bold" v-text="formatedUserBalance" />
      </div>
      <div class="flex flex-row justify-between">
        <label v-text="$t('drop.fee')" />
        <div class="font-bold" v-text="formatedTransactionFee" />
      </div>
    </div>

    <ButtonV2
      class="mt-s12"
      size="medium"
      version="primary"
      :label="$t('drop.buy')"
      testId="btn-drop-submit-order"
      :loading="isLoading"
      :inactive="!canSubmitOrder"
      @onClick="beforeSubmitOrder"
      wide
    />
  </div>
</template>

<script>
import { formatMoney } from '@/utils/formatters';
import { BaseText, ButtonV2, CustomInput } from '@/components/misc';

export default {
  name: 'DropBuyLayout',

  components: {
    BaseText,
    ButtonV2,
    CustomInput,
  },

  props: {
    dropName: {
      type: String,
      required: true,
    },
    fee: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    maxBuy: {
      type: Number,
      required: true,
    },
    playerName: {
      type: String,
      required: true,
    },
    userBalance: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      usdAmount: 0,
    };
  },

  computed: {
    canSubmitOrder() {
      return this.usdAmount > 0;
    },

    formatedTransactionFee() {
      return this.usdAmount <= 0 ? '---' : formatMoney(this.transactionFee);
    },

    formatedUserBalance() {
      return formatMoney(this.userBalance);
    },

    transactionFee() {
      return this.$big(this.fee).times(this.usdAmount || 0);
    },
  },

  methods: {
    updateAmount(value) {
      if (value === 'MAX') {
        if (value >= this.maxBuy) {
          this.usdAmount = this.$big(this.maxBuy).toFixed(2);
        } else {
          this.usdAmount = this.$big(this.userBalance).toFixed(2);
        }
      }
    },

    async beforeSubmitOrder() {
      this.$emit('onBeforeSubmitOrder', this.usdAmount);
    },
  },
};
</script>

<style scoped>
.drop-buy-container {
  width: 375px;
}

@screen md {
  .drop-buy-container {
    width: unset;
  }
}
</style>
