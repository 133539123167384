<template>
  <DropBuyLayout
    @onBeforeSubmitOrder="beforeSubmitOrder"
    :dropName="dropName"
    :fee="fee"
    :isLoading="isLoading"
    :maxBuy="maxBuy"
    :playerName="playerName"
    :userBalance="userBalance"
  />
</template>

<script>
import { mapActions } from 'vuex';
import DropBuyLayout from './layout';

import DROP_BUY from '@/graphql/mutations/DropBuy.gql';
import DROP_QUERY from '@/graphql/queries/Drop.gql';

export default {
  name: 'DropBuy',

  components: {
    DropBuyLayout
  },

  props: {
    currentUser: Object,
    drop: Object,
    dropEventData: Object,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    dropName() {
      return `${this.drop.asset.short_name || this.drop.asset.name} | DIBBS`;
    },

    fee() {
      return this.drop.fee_fraction;
    },

    playerName() {
      return this.lodashGet(this.drop, 'asset.associated_players[0].name', '');
    },

    maxBuy() {
      const maxBuy = this.drop['max_current_user_can_spend'];
      if (maxBuy > this.usdRemaining) {
        return this.usdRemaining;
      }
      return maxBuy;
    },

    usdRemaining() {
      if (!this.drop.asset_value_usd && !this.drop.supply_left) {
        return;
      }
      return this.$big(this.drop.asset_value_usd).times(this.drop.supply_left).toNumber();
    },

    userBalance() {
      return this.lodashGet(this.currentUser, 'usd_balance.amount', 0);
    },
  },

  methods: {
    ...mapActions('user', ['requiresKycComplete']),
    ...mapActions('ui', [
      'showPrompt',
      'hidePrompt',
    ]),

    async beforeSubmitOrder(usdAmount) {
      try {
        this.isLoading = true;

        await this.requiresKycComplete();

        if (this.$big(usdAmount).gt(this.maxBuy)) {
          this.isLoading = false;
          throw new Error('amount_higher_than_max_drop_buy');
        }

        const minValueToBuy = 1;

        if (this.$big(usdAmount).lt(minValueToBuy)) {
          this.isLoading = false;
          throw new Error('amount_too_small');
        }

        if (this.$big(usdAmount).gt(this.userBalance)) {
          this.isLoading = false;
          return this.showPrompt({
            eventName: 'trade__add-funds',
            title: this.$t('trade.insufficient_balance'),
            message: this.$t('trade.please_make_sure_you_have'),
            customConfirm: this.$t('wallet.add_funds'),
          });
        }

        this.showPrompt({
          title: this.$t('drops.confirm_transaction'),
          message: this.$t('drops.are_you_sure', {
            amount: this.numberFormat(usdAmount),
            asset: `${this.playerName} - ${this.dropName}`,
          }),
          actions: {
            close: () => {
              this.hidePrompt();
              this.hideModal();
            },
            submit: () => {
              this.hidePrompt();
              this.submitOrder(usdAmount);
            },
          }
        });

        await this.$store.dispatch('events/track', {
          event: 'ASSET_DROP_CHECKOUT_VIEWED',
          variables: {
            ...this.dropEventData,
          },
        });
      } catch (err) {
        await this.$store.dispatch('ui/showError', err);
      } finally {
        this.isLoading = false;
      }
    },

    async submitOrder(usdAmount) {
      try {
        this.isLoading = true;

        await this.$store.dispatch('events/track', {
          event: 'ASSET_DROP_CHECKOUT_SUBMITTED',
          variables: {
            ...this.dropEventData,
          },
        });

        await this.$apollo.mutate({
          mutation: DROP_BUY,
          variables: {
            drop_id: parseInt(this.$route.params.drop_id),
            supply_amount: this.$big(usdAmount),
          }
        });
        
        await this.$store.dispatch('api/refreshPageData', {
          $apollo: this.$apollo,
          query: DROP_QUERY,
          variables: {
            drop_id: Number(this.$route.params.drop_id),
          },
        });

        this.showModal('DropConfirmed', {
          drop: this.drop,
          dropEventData: this.dropEventData,
          dropName: this.dropName,
          playerName: this.playerName,
          usdAmount: usdAmount,
        });
      } catch (err) {
        this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
